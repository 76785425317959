import React, { useState, useEffect } from 'react';
import { CircularProgress, IconButton, Switch, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getSucursalHabilitada, updateSucursalHabilitada } from 'store/slices/sucursal';
import StoreIcon from '@mui/icons-material/Store';

const SwitchActividad = () => {
    const dispatch = useDispatch();
    const [estado, setEstado] = useState(false);
    const { sucursalHabilitada, isLoading } = useSelector((state) => state.sucursal);

    useEffect(() => {
        dispatch(getSucursalHabilitada());
    }, [dispatch]);

    useEffect(() => {
        setEstado(sucursalHabilitada);
    }, [sucursalHabilitada]);

    const handleChange = (event) => {
        const newEstado = event.target.checked;
        setEstado(newEstado);
        dispatch(
            updateSucursalHabilitada({
                estado: newEstado
            })
        );
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <Tooltip title={estado ? 'Tienda Abierta' : 'Tienda Cerrada'} placement="top">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton>
                    <StoreIcon />
                </IconButton>
                <Switch checked={estado} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
            </div>
        </Tooltip>
    );
};

export default SwitchActividad;
