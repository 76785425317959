import pedidos from './pedidos';
import dashboard from './dashboard';
import products from './products';
import analiticas from './analiticas';
import descuentos from './descuentos';
import clientes from './clientes';
import regalos from './regalos';
import encuesta from './encuesta';
import customization from './customization';

// ==============================|| MENU ITEMS ||============================== //

const menuItemsRudy = {
    items: [dashboard, pedidos, products, regalos, clientes, analiticas, encuesta, descuentos, customization]
};

const menuItemRudy = menuItemsRudy;

export default menuItemRudy;
