import React, { useState, useEffect } from 'react';

// material-ui
import { Box, FormControl, MenuItem, Select, Skeleton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// redux
import { useDispatch, useSelector } from 'store';
import { obtenerTiempoEsperaSucursal, cambiarTiempoEsperaSucursal } from 'store/slices/sucursal';
import { TIENDA_HANDY } from 'config';

const SelectTiempoDeEspera = () => {
    const dispatch = useDispatch();
    const { tiempoEsperaSucursal } = useSelector((state) => state.sucursal);
    const [value, setValue] = useState(tiempoEsperaSucursal || '00:15'); // Usando '00:15' como valor por defecto
    const theme = useTheme();
    const handleChange = (event) => {
        dispatch(cambiarTiempoEsperaSucursal(event.target.value));
    };

    useEffect(() => {
        dispatch(obtenerTiempoEsperaSucursal());
        setValue(tiempoEsperaSucursal || '00:15'); // Usando '00:15' como valor por defecto
    }, [tiempoEsperaSucursal]);

    return (
        <>
            <Typography sx={{ marginRight: '10px', color: TIENDA_HANDY ? theme.palette.common.white : theme.palette.grey[700] }}>
                Tiempo de demora{' '}
            </Typography>
            <Box sx={{ minWidth: 50, maxHeigth: 10 }} marginRight="12px">
                <FormControl>
                    <Select id="h" onChange={handleChange} value={value}>
                        <MenuItem value="00:15">15-30 mins</MenuItem>
                        <MenuItem value="00:30">30-45 mins</MenuItem>
                        <MenuItem value="00:45">45-60 mins</MenuItem>
                        <MenuItem value="00:46">45-75 mins</MenuItem>
                        <MenuItem value="01:00">60-90 mins</MenuItem>
                        <MenuItem value="01:30">90-120 mins</MenuItem>
                        <MenuItem value="02:00">120-150 mins</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </>
    );
};

export default SelectTiempoDeEspera;
