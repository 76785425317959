import React, { useState, useEffect } from 'react';

// material-ui
import { Box, FormControl, MenuItem, Select, Skeleton } from '@mui/material';

// redux
import { useDispatch } from 'store';
import { setCurrentSucursalId } from 'store/slices/pedidos';
import { getSucursalId, setSucursalId } from 'utils/axios';
import { useNegocioContext } from 'contexts/NegocioContext';
import { useSelector } from 'react-redux';

const SelectSucursal = () => {
    const dispatch = useDispatch();
    const { sucursales } = useSelector((state) => state.negocio);
    const [currentSucursal, setCurrentSucursal] = useState();

    const sucursalActual = getSucursalId();

    const handleChange = (event) => {
        if (event.target.value.toString() !== sucursalActual) {
            setSucursalId(event.target.value.toString());
            setCurrentSucursal(event.target.value);
            window.location.reload();
        }
    };

    useEffect(() => {
        dispatch(setCurrentSucursalId(currentSucursal));
    }, [dispatch, currentSucursal]);

    return (
        <Box sx={{ minWidth: 120 }} marginRight="24px">
            <FormControl fullWidth>
                {sucursales.length ? (
                    <>
                        <Select id="select-sucursal-select" value={sucursalActual} onChange={handleChange}>
                            {sucursales.map((sucursal) => (
                                <MenuItem value={sucursal.id} key={sucursal.id}>
                                    {sucursal.nombre}
                                </MenuItem>
                            ))}
                        </Select>
                    </>
                ) : (
                    <Skeleton animation="wave" variant="rectangular" width={236} height={44} />
                )}
            </FormControl>
        </Box>
    );
};

export default SelectSucursal;
