// third-party
// import { FormattedMessage } from 'react-intl';

import { IconGift } from '@tabler/icons';

const icons = {
    IconGift
};

// ==============================|| PRODUCTS MENU ITEMS ||============================== //

const regalos = {
    id: 'Gifts',
    title: '',
    type: 'group',
    children: [
        {
            id: 'gifts',
            title: 'Regalos',
            type: 'item',
            url: '/gifts',
            icon: icons.IconGift,
            breadcrumbs: false
        }
    ]
};

export default regalos;
