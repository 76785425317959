// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { setGlobalLoading } from './globalSlice';
// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    info: {},
    horarios: [],
    metodosPago: [],
    mapa_zonas: [],
    sucursalHabilitada: false,
    aceptaDelivery: false,
    aceptaTakeAway: false,
    tiempoEsperaSucursal: undefined,
    tiempoTakeAwaySucursal: undefined,
    encuesta: {
        promedioTotal: '4',
        promedioSucursal: '5',
        cantidadRespuestas: '30',
        promedioPregunta: '4',
        nps: '20',
        respuestas: []
    },
    encuestaDetalle: []
};

const slice = createSlice({
    name: 'sucursal',
    initialState,
    reducers: {
        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET INFO SUCURSAL
        getInfoSucursalSuccess(state, action) {
            state.info = action.payload;
        },

        // ACTUALIZAR INFO SUCURSAL
        changeConfigGralSucursal(state) {
            state.error = null;
        },

        // GET HORARIOS SUCURSAL
        getHorariosSucursalSuccess(state, action) {
            state.horarios = action.payload;
        },

        // GET METODOS DE PAGO SUCURSAL
        getMetodosPAgoSucursalSuccess(state, action) {
            state.metodosPago = action.payload;
        },

        // GET MAPA SUCURSAL
        getZonasSucursalSuccess(state, action) {
            state.mapa_zonas = action.payload;
        },

        // BORRAR INFO SUCURSAL
        limpiarInfoSucursalSuccess(state) {
            state.info = {};
        },

        // BORRAR INFO METODOS DE PAGO
        limpiarMetodosPagoSucursalSuccess(state) {
            state.metodosPago = {};
        },

        // BORRAR HORARIOS SUCURSAL
        limpiarHorariosSucursalSuccess(state) {
            state.horarios = {};
        },

        // BORRAR ZONAS SUCURSAL
        limpiarZonasSucursalSuccess(state) {
            state.mapa_zonas = {};
        },

        // ELIMINAR HORARIO SUCURSAL
        eliminarHorarioSucursalSuccess(state) {
            state.error = null;
        },

        // ELIMINAR METODOS SUCURSAL
        eliminarMetodoSucursalSuccess(state) {
            state.error = null;
        },

        // AGREGAR HORARIO SUCURSAL
        agregarHorarioSucursalSuccess(state) {
            state.error = null;
        },

        // AGREGAR HORARIO SUCURSAL
        agregarMetodoSucursalSuccess(state) {
            state.error = null;
        },

        // ACTUALIZAR HORARIOS SUCURSAL
        actualizarHorariosSucursalSuccess(state) {
            state.error = null;
        },

        // ACTUALIZAR HORARIOS SUCURSAL
        actualizarMetodoSucursalSuccess(state) {
            state.error = null;
        },

        // AGREGAR ZONA SUCURSAL
        agregarNuevaZonaSucursalSuccess(state) {
            state.error = null;
        },

        // EDITAR ZONA SUCURSAL
        editarZonaSucursalSuccess(state) {
            state.error = null;
        },

        // ELIMINAR ZONA SUCURSAL
        eliminarZonaSucursalSuccess(state) {
            state.error = null;
        },

        // GET-HORARIO-ACTIVIDAD
        getSucursalHabilitadaSuccess(state, action) {
            state.sucursalHabilitada = action.payload;
        },

        // GET-DELIVERY-SUCURSAL
        getAceptaDeliverySuccess(state, action) {
            state.aceptaDelivery = action.payload;
        },
        // GET-TAKE-AWAY
        getAceptaTakeAwaySuccess(state, action) {
            state.aceptaTakeAway = action.payload;
        },
        // SET TIEMPO DE ESPERA SUCURSAL
        setTiempoEsperaSucursalSuccess(state, action) {
            state.tiempoEsperaSucursal = action.payload;
        },
        // SET TIEMPO TAKEAWAY SUCURSAL
        setTiempoTakeAwaySucursalSuccess(state, action) {
            state.tiempoTakeAwaySucursal = action.payload;
        },
        // GET DATOS ENCUESTA SUCURSAL
        getDatosEncuestaSucursalSuccess(state, action) {
            state.encuesta.promedioTotal = action.payload.data.promedio_total;
            state.encuesta.promedioSucursal = action.payload.data.promedio_sucursal;
            state.encuesta.cantidadRespuestas = action.payload.data.detalles.cantidad_respuestas;
            state.encuesta.promedioPregunta = action.payload.data.detalles.promedio;
            state.encuesta.nps = action.payload.data.detalles.nps;
            state.encuesta.respuestas = action.payload.data.detalles.respuestas_pregunta;
        },
        getDetalleEncuestaSuccess(state, action) {
            state.encuestaDetalle = action.payload.data;
            state.isLoading = false;
        }
    }
});

// -----------------------------------------------------

export function getInfoSucursal(id) {
    return async () => {
        try {
            const response = await axios.get('/getSucursal', {
                params: {
                    sucursal_id: id
                }
            });

            dispatch(slice.actions.getInfoSucursalSuccess(response.data.data.sucursal));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateAceptaDelivery(info) {
    return async () => {
        dispatch(setGlobalLoading(true));
        try {
            const response = await axios.post('/updateEstadoTienda', {
                habilitado: info.estado,
                tipo: 'delivery'
            });
            dispatch(slice.actions.getAceptaDeliverySuccess(response.data.estado));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(setGlobalLoading(false));
        }
    };
}

export function updateAceptaTakeAway(info) {
    return async () => {
        dispatch(setGlobalLoading(true));
        try {
            const response = await axios.post('/updateEstadoTienda', {
                habilitado: info.estado,
                tipo: 'take_away'
            });
            dispatch(slice.actions.getAceptaTakeAwaySuccess(response.data.estado));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(setGlobalLoading(false));
        }
    };
}

export function getSucursalHabilitada() {
    dispatch(slice.actions.setLoading(true));
    return async () => {
        try {
            const response = await axios.post('/getHorarioActividad');
            dispatch(slice.actions.getSucursalHabilitadaSuccess(response.data.sucursal_habilitada));
            dispatch(slice.actions.getAceptaDeliverySuccess(response.data.aceptaDelivery));
            dispatch(slice.actions.getAceptaTakeAwaySuccess(response.data.aceptaTakeAway));
            dispatch(slice.actions.setLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.setLoading(false));
        }
    };
}

export function updateSucursalHabilitada(info) {
    return async () => {
        dispatch(setGlobalLoading(true));
        try {
            const response = await axios.post('/updateEstadoTienda', {
                id_sucursal: info.id,
                habilitado: info.estado,
                tipo: 'estadoActual'
            });
            dispatch(slice.actions.getSucursalHabilitadaSuccess(response.data.estado));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(setGlobalLoading(false));
        }
    };
}

export function limpiarInfoSucursal() {
    return async () => {
        try {
            dispatch(slice.actions.limpiarInfoSucursalSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function limpiarMetodosPagoSucursal() {
    return async () => {
        try {
            dispatch(slice.actions.limpiarMetodosPagoSucursalSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getHorariosSucursal(id) {
    return async () => {
        try {
            const response = await axios.get('/getSucursalHorarios', {
                params: {
                    sucursal_id: id
                }
            });

            dispatch(slice.actions.getHorariosSucursalSuccess(response.data.data.dia_horarios));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getMetodosPagoSucursal(id) {
    return async () => {
        try {
            const response = await axios.get('/getMetodosPagoSucursal', {
                params: {
                    sucursal_id: id
                }
            });

            dispatch(slice.actions.getMetodosPAgoSucursalSuccess(response.data.data.metodos_pago));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function limpiarHorariosSucursal() {
    return async () => {
        try {
            dispatch(slice.actions.limpiarHorariosSucursalSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function eliminarHorarioSucursal(info) {
    return async () => {
        try {
            await axios.post('/deleteSucursalHorario', info);

            dispatch(slice.actions.eliminarHorarioSucursalSuccess());
            dispatch(getHorariosSucursal(info.sucursal_id));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function agregarHorarioSucursal(info) {
    return async () => {
        try {
            await axios.post('/addSucursalHorario', info);

            dispatch(slice.actions.agregarHorarioSucursalSuccess());
            dispatch(getHorariosSucursal(info.sucursal_id));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export const agregarMetodoSucursal = (info) => async (dispatch) => {
    try {
        await axios.post('/agregarMetodoSucursal', info);
        dispatch(slice.actions.agregarMetodoSucursalSuccess());
        dispatch(getMetodosPagoSucursal(info.sucursal_id));
    } catch (error) {
        dispatch(slice.actions.hasError(error.message));
    }
};

export const actualizarHorariosSucursal = (info) => async (dispatch) => {
    dispatch(slice.actions.setLoading(true));

    try {
        await axios.post('/updateSucursalHorarios', info);
        dispatch(slice.actions.actualizarHorariosSucursalSuccess());
        dispatch(getHorariosSucursal(info.sucursal_id));
    } catch (error) {
        console.error(error.message);
        dispatch(slice.actions.hasError(error.message));
    } finally {
        dispatch(slice.actions.setLoading(false));
    }
};

export function actualizarMetodosSucursal(info) {
    return async () => {
        dispatch(setGlobalLoading(true));
        try {
            await axios.post('/updateMetodosSucursal', info);
            dispatch(slice.actions.actualizarMetodoSucursalSuccess());
            dispatch(getMetodosPagoSucursal(info.sucursal_id));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(setGlobalLoading(false));
        }
    };
}

export function getZonasSucursal(id) {
    return async () => {
        try {
            const response = await axios.get('/getSucursalZonas', {
                params: {
                    sucursal_id: id
                }
            });

            dispatch(slice.actions.getZonasSucursalSuccess(response.data.data.mapa_zonas));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function limpiarZonasSucursal() {
    return async () => {
        try {
            dispatch(slice.actions.limpiarZonasSucursalSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function agregarNuevaZonaSucursal(info) {
    return async () => {
        try {
            await axios.post('/addSucursalZona', info);

            dispatch(slice.actions.agregarNuevaZonaSucursalSuccess());
            dispatch(getZonasSucursal(info.sucursal_id));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editarZonaSucursal(info) {
    return async () => {
        try {
            await axios.post('/updateSucursalZona', info);

            dispatch(slice.actions.editarZonaSucursalSuccess());
            dispatch(getZonasSucursal(info.sucursal_id));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function eliminarZonaSucursal(info) {
    return async () => {
        try {
            await axios.post('/deleteSucursalZona', info);

            dispatch(slice.actions.eliminarZonaSucursalSuccess());
            dispatch(getZonasSucursal(info.sucursal_id));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function actualizarConfigGralSucursal(info) {
    return async () => {
        try {
            await axios.post('/editSucursal', info);

            dispatch(slice.actions.changeConfigGralSucursal());
            dispatch(getInfoSucursal(info.sucursal_id));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function guardarImpresoraSucursal(info) {
    return async () => {
        try {
            const response = await axios.post('/updateSucursalImpresora', info);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function obtenerTiempoEsperaSucursal() {
    return async () => {
        try {
            const response = await axios.get('/getTiempoEsperaSucursalNew');
            dispatch(slice.actions.setTiempoEsperaSucursalSuccess(response.data.tiempo_espera));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function cambiarTiempoEsperaSucursal(tiempoEntrega) {
    return async () => {
        dispatch(setGlobalLoading(true));
        try {
            const response = await axios.post(`/updateTiempoEsperaSucursal`, { tiempo_entrega: tiempoEntrega });
            dispatch(slice.actions.setTiempoEsperaSucursalSuccess(tiempoEntrega));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(setGlobalLoading(false));
        }
    };
}

export function obtenerDatosEncuestaSucursal(fechaInicio, fechaFin, preguntaId) {
    return async () => {
        let pregunta = null;
        if (preguntaId > 0) {
            pregunta = preguntaId;
        }
        try {
            const response = await axios.get(`/obtenerRespuestasConNpsYPromedios`, {
                params: {
                    pregunta_id: pregunta,
                    fecha_inicio: fechaInicio,
                    fecha_fin: fechaFin
                }
            });

            dispatch(slice.actions.getDatosEncuestaSucursalSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function obtenerDetalleEncuesta(formularioId) {
    return async () => {
        try {
            dispatch(slice.actions.setLoading(true));
            const response = await axios.get(`/obtenerRespuestasPorFormularioId`, {
                params: {
                    formulario_id: formularioId
                }
            });

            dispatch(slice.actions.getDetalleEncuestaSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function obtenerTiempoTakeAwaySucursal() {
    return async () => {
        try {
            dispatch(slice.actions.setLoading(true));
            const response = await axios.get(`/getTiempoEsperaTakeawaySucursal`);
            dispatch(slice.actions.setTiempoTakeAwaySucursalSuccess(response.data.tiempo_espera_takeaway));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
        dispatch(slice.actions.setLoading(false));
    };
}

export function cambiarTiempoTakeAwaySucursal(tiempoTakeAway) {
    return async () => {
        dispatch(setGlobalLoading(true));
        try {
            dispatch(slice.actions.setLoading(true));
            const response = await axios.post(`/updateTiempoEsperaTakeawaySucursal`, {
                tiempo_entrega_takeaway: tiempoTakeAway
            });
            dispatch(slice.actions.setTiempoTakeAwaySucursalSuccess(tiempoTakeAway));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(setGlobalLoading(false));
            dispatch(slice.actions.setLoading(false));
        }
    };
}

// metodos de pago

export function eliminarMetodoSucursal(info) {
    return async () => {
        try {
            await axios.post('/deleteMetodoSucursal', info);

            dispatch(slice.actions.eliminarMetodoSucursalSuccess());
            dispatch(getMetodosPagoSucursal(info.sucursal_id));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Reducer
export default slice.reducer;
