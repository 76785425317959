// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    info: {},
    encuesta: {
        promedioTotal: '4',
        promedioSucursal: '5',
        cantidadRespuestas: '30',
        promedioPregunta: '4',
        nps: '20',
        respuestas: []
    },
    filtros: {
        fechaInicio: new Date(new Date().setMonth(new Date().getMonth() - 11)),
        fechaFin: new Date(),
        pregunta: '0'
    },
    encuestaDetalle: []
};

const slice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET DATOS ENCUESTA SUCURSAL
        getDatosEncuestaSucursalSuccess(state, action) {
            state.encuesta.promedioTotal = action.payload.data.promedio_total;
            state.encuesta.promedioSucursal = action.payload.data.promedio_sucursal;
            state.encuesta.cantidadRespuestas = action.payload.data.detalles.cantidad_respuestas;
            state.encuesta.promedioPregunta = action.payload.data.detalles.promedio;
            state.encuesta.nps = action.payload.data.detalles.nps;
            state.encuesta.respuestas = action.payload.data.detalles.respuestas_pregunta;
        },
        getDetalleEncuestaSuccess(state, action) {
            state.encuestaDetalle = action.payload.data;
            state.isLoading = false;
        },
        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        setFechaInicio(state, action) {
            state.filtros.fechaInicio = action.payload;
        },

        setFechaFin(state, action) {
            state.filtros.fechaFin = action.payload;
        },

        setPregunta(state, action) {
            state.filtros.pregunta = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// -----------------------------------------------------

export function obtenerDatosEncuestaSucursal(fechaInicio, fechaFin, preguntaId) {
    return async () => {
        let pregunta = null;
        if (preguntaId > 0) {
            pregunta = preguntaId;
        }
        try {
            const response = await axios.get(`/obtenerRespuestasConNpsYPromedios`, {
                params: {
                    pregunta_id: pregunta,
                    fecha_inicio: fechaInicio,
                    fecha_fin: fechaFin
                }
            });
            dispatch(slice.actions.getDatosEncuestaSucursalSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function obtenerDetalleEncuesta(formularioId) {
    return async () => {
        try {
            dispatch(slice.actions.setLoading(true));
            const response = await axios.get(`/obtenerRespuestasPorFormularioId`, {
                params: {
                    formulario_id: formularioId
                }
            });
            dispatch(slice.actions.getDetalleEncuestaSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setFechaInicio(fecha) {
    return async () => {
        dispatch(slice.actions.setFechaInicio(fecha));
    };
}

export function setFechaFin(fecha) {
    return async () => {
        dispatch(slice.actions.setFechaFin(fecha));
    };
}

export function setPregunta(pregunta) {
    return async () => {
        dispatch(slice.actions.setPregunta(pregunta));
    };
}
