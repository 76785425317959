import { IconScreenshot } from '@tabler/icons';

// ==============================|| CUSTOMIZATION MENU ITEMS ||============================== //

const customization = {
    id: 'customization',
    title: '',
    type: 'group',
    children: [
        {
            id: 'Personalización',
            title: 'Personalización',
            type: 'item',
            url: '/negocio/configuracion-web',
            icon: IconScreenshot,
            breadcrumbs: false
        }
    ]
};

export default customization;
