// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Skeleton,
    Typography
} from '@mui/material';

// assets
import { IconBuildingStore } from '@tabler/icons';

import { useDispatch, useSelector } from 'store';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getPedidos } from 'store/slices/pedidos';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| PEDIDOS LIST ITEM ||============================== //

const PedidosList = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const { pedidos, cargando } = useSelector((state) => state.pedidos);

    const pedidosIngresados = pedidos.filter((pedido) => pedido.estado === 'INGRESADO');

    useEffect(() => {
        if (!pedidos.length) {
            dispatch(getPedidos());
        }
    }, [dispatch, pedidos.length]);

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {pedidosIngresados.map((pedido) => (
                <>
                    <ListItemWrapper key={pedido.id}>
                        <Link to="/negocio/pedidos/administracion">
                            <ListItem alignItems="center">
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            color: theme.palette.success.dark,
                                            backgroundColor:
                                                theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
                                            border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                                            borderColor: theme.palette.success.main
                                        }}
                                    >
                                        <IconBuildingStore stroke={1.5} size="1.3rem" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle1" sx={{ textDecoration: 'none' }}>
                                            Se ingreso un nuevo pedido!
                                        </Typography>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <Grid container justifyContent="flex-end" marginTop={6}>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" display="block" gutterBottom>
                                                Pedido #{pedido.id}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Link>
                        <Grid container direction="column" className="list-container">
                            {/* <Grid item xs={12} sx={{ pb: 2 }}>
                                <Typography variant="subtitle2">We have successfully received your request.</Typography>
                            </Grid> */}
                            {/* <Grid item xs={12}>
                                <Grid container>
                                    <Grid item>
                                        <Chip label="Unread" sx={chipErrorSX} />
                                    </Grid>
                                </Grid>
                            </Grid> */}
                        </Grid>
                    </ListItemWrapper>
                    <Divider marginBottom={1} />
                </>
            ))}
            {cargando ? (
                <>
                    <Box>
                        <Typography padding={2} sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                            Cargando Notificaciones
                        </Typography>
                    </Box>
                    <ListItemWrapper>
                        <ListItem alignItems="center">
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        color: theme.palette.success.dark,
                                        backgroundColor:
                                            theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
                                        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                                        borderColor: theme.palette.success.main
                                    }}
                                >
                                    <IconBuildingStore stroke={1.5} size="1.3rem" />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={<Skeleton />} />
                            <ListItemSecondaryAction>
                                <Grid container justifyContent="flex-end" marginTop={6}>
                                    <Grid item xs={12}>
                                        <Skeleton />
                                    </Grid>
                                </Grid>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </ListItemWrapper>
                    <ListItemWrapper>
                        <ListItem alignItems="center">
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        color: theme.palette.success.dark,
                                        backgroundColor:
                                            theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
                                        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                                        borderColor: theme.palette.success.main
                                    }}
                                >
                                    <IconBuildingStore stroke={1.5} size="1.3rem" />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={<Skeleton />} />
                            <ListItemSecondaryAction>
                                <Grid container justifyContent="flex-end" marginTop={6}>
                                    <Grid item xs={12}>
                                        <Skeleton />
                                    </Grid>
                                </Grid>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </ListItemWrapper>
                </>
            ) : (
                <></>
            )}
        </List>
    );
};

export default PedidosList;
