// third-party
// import { FormattedMessage } from 'react-intl';

// assets
import { IconClipboardText } from '@tabler/icons';

// constant
const icons = {
    IconClipboardText
};

// ==============================|| PRODUCTS MENU ITEMS ||============================== //

const encuesta = {
    id: 'encuesta',
    title: '',
    type: 'group',
    children: [
        {
            id: 'encuesta',
            title: 'Encuesta',
            type: 'item',
            url: '/encuesta',
            icon: icons.IconClipboardText,
            breadcrumbs: false
        }
    ]
};

export default encuesta;
