// third-party
// import { FormattedMessage } from 'react-intl';

// assets
import { IconDiscount2 } from '@tabler/icons';

// constant
const icons = {
    IconDiscount2
};

// ==============================|| PRODUCTS MENU ITEMS ||============================== //

const descuentos = {
    id: 'Desc',
    title: '',
    type: 'group',
    children: [
        {
            id: 'Promociones',
            title: 'Promociones',
            type: 'item',
            url: '/discount/discount-list',
            icon: icons.IconDiscount2,
            breadcrumbs: false
        }
    ]
};

export default descuentos;
