/**
 * axios setup to use mock service
 */
import { HOST_API } from '../config';
import axios from 'axios';

const axiosServices = axios.create({
    baseURL: HOST_API,
    headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
        // set the request's mode to 'no-cors',
        // so that the response doesn't have the 'Access-Control-Allow-Origin' header
        mode: 'no-cors'
    },
    withCredentials: false,
    responseType: 'json',
    timeout: 10000000,
    maxBodyLength: 5000000,
    maxContentLength: 5000000
});

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || error)
);

const setToken = (token) => {
    localStorage.setItem('token', token);
};

const getToken = () => localStorage.getItem('token');

const setSucursalId = (sucursalId) => {
    localStorage.setItem('id_sucursal', sucursalId);
};

const getSucursalId = () => localStorage.getItem('id_sucursal');

const removeToken = () => {
    localStorage.removeItem('token');
};

axiosServices.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers.SucursalId = getSucursalId();
        }
        return config;
    },
    (error) => Promise.reject(error) // reject the promise if error
);

export { setToken, removeToken, getToken, setSucursalId, getSucursalId };

export default axiosServices;
