// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconTag } from '@tabler/icons';

// constant
const icons = {
    IconTag
};

// ==============================|| PRODUCTS MENU ITEMS ||============================== //

const productos = {
    id: 'Menu',
    title: '',
    type: 'group',
    children: [
        {
            id: 'Productos',
            title: <FormattedMessage id="productos" />,
            type: 'collapse',
            icon: icons.IconTag,
            children: [
                {
                    id: 'productos',
                    title: 'Productos',
                    type: 'item',
                    url: '/shop/product/product-list',
                    breadcrumbs: false,
                    hidden: false
                },
                {
                    id: 'categorias',
                    title: 'Categorias',
                    type: 'item',
                    url: '/shop/category/category-list',
                    breadcrumbs: false
                },
                {
                    id: 'ordenarcategorias',
                    title: 'Ordenar Categorías',
                    type: 'item',
                    url: 'negocio/configuracion-web',
                    breadcrumbs: false,
                    hidden: false
                },
                {
                    id: 'opciones',
                    title: 'Opciones',
                    type: 'item',
                    url: '/shop/option/option-list',
                    breadcrumbs: false,
                    hidden: false
                }
            ]
        }
    ]
};

export default productos;
