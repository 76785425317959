// third-party
// import { FormattedMessage } from 'react-intl';

// assets
import { IconAntennaBars5 } from '@tabler/icons';

// constant
const icons = {
    IconAntennaBars5
};

// ==============================|| PRODUCTS MENU ITEMS ||============================== //

const analiticas = {
    id: 'Analisis',
    title: '',
    type: 'group',
    children: [
        {
            id: 'analisis',
            title: 'Analisis',
            type: 'item',
            url: '/analisis/ventas',
            icon: icons.IconAntennaBars5,
            breadcrumbs: false
        }
    ]
};

export default analiticas;
