// third-party
import { createSlice } from '@reduxjs/toolkit';
import { tr } from 'date-fns/locale';
import { toInteger } from 'lodash';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    customers: [],
    customersToExport: [],
    customersList: [],
    cargando: false,
    totalClientes: 0,
    filtros: {
        sinPedido: 0,
        conPedido: 0
    },
    customersFiltrados: [],
    urlImagen: '',
    idImagen: '',
    status: 0,
    datosPedidosCliente: []
};

const slice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET CUSTOMERS
        getCustomersSuccess(state, action) {
            state.customers = action.payload;
        },

        getCustomersToExportSuccess(state, action) {
            state.customersToExport = action.payload;
        },

        getCustomersListSuccess(state, action) {
            state.customersList = action.payload;
        },

        // LIMPIAR CUSTOMERS
        limpiarCustomersSuccess(state) {
            state.customers = [];
        },

        // FILTRAR SUCCESS
        filtrarClientesSuccess(state, action) {
            state.customersFiltrados = action.payload;
        },

        setUrlImagen(state, action) {
            state.urlImagen = action.payload;
        },

        setIdImagenWpp(state, action) {
            state.idImagen = action.payload;
        },

        // SET CATEGORIES
        setStatusSuccess(state, action) {
            state.status = action.payload;
        },

        // SET TOTAL CLIENTES
        setTotalClientes(state, action) {
            state.totalClientes = action.payload;
        },

        // SET CARGANDO = TRUE
        setCargandoSuccess(state) {
            state.cargando = true;
        },

        // SET CARGANDO = FALSE
        unsetCargandoSuccess(state) {
            state.cargando = false;
        },

        // SET FILTROS
        setCustomerFiltrosSuccess(state, action) {
            state.filtros = action.payload;
        },

        statusReset(state) {
            state.status = '';
        },

        getDatosPedidosClienteSuccess(state, action) {
            state.datosPedidosCliente = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function setCargando() {
    return async () => {
        try {
            dispatch(slice.actions.setCargandoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function unsetCargando() {
    return async () => {
        try {
            dispatch(slice.actions.unsetCargandoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getCustomers(filtros) {
    return async () => {
        try {
            dispatch(setCargando());
            const response = await axios.get(`/getClientes`, {
                params: {
                    negocio_id: filtros.idNegocio,
                    pagina: filtros.pagina,
                    cantidad: filtros.cantidad,
                    orden: filtros.orden,
                    tipo_orden: filtros.tipo_orden,
                    busqueda: filtros.busqueda
                }
            });
            dispatch(slice.actions.getCustomersSuccess(response.data.data));
            dispatch(slice.actions.setTotalClientes(response.data.total_clientes));
            dispatch(unsetCargando());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getCustomersToExport(idNegocio) {
    return async () => {
        try {
            dispatch(setCargando());
            const response = await axios.get(`/getClientesToExport`, {
                params: {
                    negocio_id: idNegocio
                }
            });

            dispatch(slice.actions.getCustomersToExportSuccess(response.data.clientes));

            dispatch(unsetCargando());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getCustomersList(idNegocio) {
    return async () => {
        try {
            dispatch(setCargando());
            const response = await axios.get(`/getClientesList`, {
                params: {
                    negocio_id: idNegocio
                }
            });
            dispatch(slice.actions.getCustomersListSuccess(response.data.data));
            dispatch(unsetCargando());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function limpiarCustomers() {
    return async () => {
        try {
            dispatch(slice.actions.limpiarCustomersSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setCustomerFiltros(filtros) {
    return async () => {
        try {
            dispatch(slice.actions.setCustomerFiltrosSuccess(filtros));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function filtrarClientes(filtros, clientes) {
    return async () => {
        try {
            if (typeof filtros === 'string' || filtros instanceof String) {
                const estadoString = filtros.toString();
                // eslint-disable-next-line eqeqeq
                if (estadoString != 'TODOS') {
                    switch (estadoString) {
                        case '0':
                            // filtrar clientes que tienen total_pedidos igual a 0
                            dispatch(slice.actions.filtrarClientesSuccess(clientes.filter((cliente) => cliente.total_pedidos === 0)));
                            break;
                        case '1':
                            // filtrar clientes que tienen al menos 1 pedido
                            dispatch(slice.actions.filtrarClientesSuccess(clientes.filter((cliente) => cliente.total_pedidos > 0)));
                            break;
                        default:
                            break;
                    }
                } else {
                    // filtrar por los distintos campos
                    dispatch(slice.actions.filtrarClientesSuccess(clientes));
                }
            } else {
                try {
                    const filtrosPedidos = {};
                    Object.keys(filtros).forEach((key) => {
                        if (filtros[key] !== undefined) {
                            filtrosPedidos[key] = filtros[key];
                        }
                    });

                    const filtrosArray = Object.keys(filtrosPedidos);
                    const clientesFiltrados = filtrosArray.reduce(
                        (acc, filtro) => acc.filter((pedido) => pedido[filtro] === filtrosPedidos[filtro]),
                        clientes
                    );
                    dispatch(slice.actions.filtrarClientesSuccess(clientesFiltrados));
                } catch (error) {
                    dispatch(slice.actions.hasError(error));
                }
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function importClientes(data) {
    return async () => {
        try {
            const formData = new FormData();
            formData.append('file', data.file);
            formData.append('id_sucursal', data.id_sucursal);

            const response = await axios.post(`/importarClientes`, formData);
            dispatch(slice.actions.setStatusSuccess(response.data.code));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function guardarArchivoUrlWsp(data) {
    return async () => {
        try {
            const response = await axios.post(`/guardarArchivoUrlWsp`, data);
            dispatch(slice.actions.setIdImagenWpp(response.data.idImagen));
            dispatch(slice.actions.statusReset());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function subirImagenS3(data) {
    return async () => {
        try {
            const formData = new FormData();
            formData.append('archivo', data.selectedFileImage);
            formData.append('id_sucursal', 79);

            const response = await axios.post(`/subirImagenS3`, formData);
            dispatch(slice.actions.setUrlImagen(response.data.urlImagen));
            dispatch(slice.actions.statusReset());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// enviar mensaje
export function enviarMensaje(data) {
    return async () => {
        try {
            const response = await axios.post(`/enviarMensaje`, data);
            dispatch(slice.actions.setStatusSuccess(response.data.code));
            dispatch(slice.actions.statusReset());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPedidosCliente(idCliente, idNegocio) {
    dispatch(setCargando());
    return async () => {
        try {
            const response = await axios.get(`/getPedidosCliente`, {
                params: {
                    id_cliente: idCliente,
                    id_negocio: idNegocio
                }
            });
            dispatch(slice.actions.getDatosPedidosClienteSuccess(response.data.datosPedidos));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
        dispatch(unsetCargando());
    };
}
