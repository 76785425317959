import pedidos from './pedidos';
import products from './products';
import descuentos from './descuentos';

// ==============================|| MENU ITEMS ||============================== //

const menuItemsVendedor = {
    items: [pedidos]
};
const menuItems = menuItemsVendedor;

export default menuItems;
