import React, { useState, useEffect } from 'react';

// material-ui
import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TIENDA_HANDY } from 'config';

// redux
import { useDispatch, useSelector } from 'store';
import { obtenerTiempoTakeAwaySucursal, cambiarTiempoTakeAwaySucursal } from 'store/slices/sucursal';

const SelectTiempoTakeAway = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { tiempoTakeAwaySucursal } = useSelector((state) => state.sucursal);

    const [value, setValue] = useState(tiempoTakeAwaySucursal || '00:10');

    const handleChange = (event) => {
        dispatch(cambiarTiempoTakeAwaySucursal(event.target.value));
    };

    useEffect(() => {
        dispatch(obtenerTiempoTakeAwaySucursal());

        setValue(tiempoTakeAwaySucursal || '00:10');
    }, [tiempoTakeAwaySucursal]);

    return (
        <>
            <Typography sx={{ marginRight: '10px', color: TIENDA_HANDY ? theme.palette.common.white : theme.palette.grey[700] }}>
                Tiempo takeaway{' '}
            </Typography>

            <Box sx={{ minWidth: 50, maxHeigth: 10 }} marginRight="12px">
                <FormControl>
                    <Select id="h" onChange={handleChange} value={value}>
                        <MenuItem value="00:10">10&apos;</MenuItem>
                        <MenuItem value="00:15">15&apos;</MenuItem>
                        <MenuItem value="00:20">20&apos;</MenuItem>
                        <MenuItem value="00:25">25&apos;</MenuItem>
                        <MenuItem value="00:30">30&apos;</MenuItem>
                        <MenuItem value="00:35">35&apos;</MenuItem>
                        <MenuItem value="00:40">40&apos;</MenuItem>
                        <MenuItem value="00:45">45&apos;</MenuItem>
                        <MenuItem value="00:50">50&apos;</MenuItem>
                        <MenuItem value="00:55">55&apos;</MenuItem>
                        <MenuItem value="01:00">60&apos;</MenuItem>
                        <MenuItem value="01:05">65&apos;</MenuItem>
                        <MenuItem value="01:10">70&apos;</MenuItem>
                        <MenuItem value="01:15">75&apos;</MenuItem>
                        <MenuItem value="01:20">80&apos;</MenuItem>
                        <MenuItem value="01:25">85&apos;</MenuItem>
                        <MenuItem value="01:30">90&apos;</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </>
    );
};

export default SelectTiempoTakeAway;
