import PropTypes from 'prop-types';
import { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { getInfoNegocio } from 'store/slices/negocio';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    LinearProgress,
    linearProgressClasses
} from '@mui/material';

// assets
import { IconBuildingStore } from '@tabler/icons';
import { TIENDA_HANDY } from 'config';

// styles
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 30,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.light : '#fff'
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main
    }
}));

const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
    marginBottom: '22px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '157px',
        height: '157px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary[200],
        borderRadius: '50%',
        top: '-105px',
        right: '-96px'
    }
}));

function MenuCard() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { info } = useSelector((state) => state.negocio);

    useEffect(() => {
        if (!info?.negocio_url) {
            dispatch(getInfoNegocio());
        }
    }, [dispatch, info.negocio_url]);

    const urlNegocio = info.negocio_url ? `https://${info.negocio_url}` : '';

    const handleCardClick = () => {
        if (urlNegocio && urlNegocio.startsWith('https://')) {
            window.open(urlNegocio, '_blank');
        }
    };

    return (
        <CardStyle sx={{ mt: TIENDA_HANDY ? '20px' : 0, borderRadius: TIENDA_HANDY ? 0 : '16px' }}>
            <CardContent
                sx={{
                    p: 2,
                    cursor: 'pointer',
                    '&:hover': { opacity: 0.9 }
                }}
                onClick={handleCardClick}
                onKeyPress={(e) => e.key === 'Enter' && handleCardClick()}
                role="button"
                tabIndex={0}
            >
                <List sx={{ p: 0, m: 0 }}>
                    <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
                        <ListItemAvatar sx={{ mt: 0 }}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.largeAvatar,
                                    color: theme.palette.primary.main,
                                    border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                                    borderColor: theme.palette.primary.main,
                                    background: theme.palette.mode === 'dark' ? theme.palette.dark.dark : '#fff',
                                    marginRight: '12px'
                                }}
                            >
                                <IconBuildingStore fontSize="inherit" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{ mt: 0 }}
                            primary={
                                <Typography
                                    variant="subtitle1"
                                    sx={{ color: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary[800] }}
                                >
                                    Ver mi tienda
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
            </CardContent>
        </CardStyle>
    );
}

export default memo(MenuCard);
