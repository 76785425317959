export const JWT_API = {
    secret: 'SECRET-KEY',
    timeout: '1 days'
};

export const HOST_API = process.env.REACT_APP_HOST;
export const TIENDA_HANDY = process.env.REACT_APP_TIENDA_HANDY;

export const URL_LOGIN_HANDY = process.env.REACT_APP_URL_LOGIN_HANDY;

export const FIREBASE_API = {
    apiKey: 'AIzaSyB-hmhIl7jEYZWdWh7Z2lmJxPBzwbVH-sE',
    authDomain: 'dashboard-habitue.firebaseapp.com',
    projectId: 'dashboard-habitue',
    storageBucket: 'dashboard-habitue.appspot.com',
    messagingSenderId: '419413201927',
    appId: '1:419413201927:web:e445c1282ff678568a86a2',
    measurementId: 'G-QK9S6J9CTD'
};

export const AUTH0_API = {
    client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
    domain: 'dev-w0-vxep3.us.auth0.com'
};

export const AWS_API = {
    poolId: 'us-east-1_AOfOTXLvD',
    appClientId: '3eau2osduslvb7vks3vsh9t7b0'
};

export const GOOGLE_MAP_API = {
    apiKey: 'AIzaSyB9sjzPILiqZdjk-0F2EAaBOTVlT_ig-eU'
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/dashboard/default';

const tiendaHandyConfig = {
    fontFamily: "'Roboto', sans-serif",
    borderRadius: 0,
    presetColor: 'theme7',
    outlinedFilled: true,
    navType: 'light',
    locale: 'es',
    rtlLayout: false,
    container: false
};

const defaultConfig = {
    fontFamily: "'Roboto', sans-serif",
    borderRadius: 16,
    presetColor: 'default',
    outlinedFilled: true,
    navType: 'light',
    locale: 'es',
    rtlLayout: false,
    container: false
};

const config = {
    ...(TIENDA_HANDY ? tiendaHandyConfig : defaultConfig)
};

export default config;
