import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// action - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import { FIREBASE_API } from 'config';

// firebase initialize
if (!firebase.apps.length) {
    firebase.initializeApp(FIREBASE_API);
}

// const
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('id_negocio');
    localStorage.removeItem('tipo_negocio');
    localStorage.removeItem('id_sucursal');
    firebase.auth().signOut();
};

export const FirebaseProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(
        () =>
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    // eslint-disable-next-line no-underscore-dangle
                    // imprimir access token
                    user.getIdToken().then((token) => {
                        fetch(`https://api-prod.habitueonline.com/v1/checkLogin`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Accept: '*/*',
                                mode: 'no-cors'
                            },
                            body: JSON.stringify({
                                email: user.email,
                                accessToken: token
                            })
                        })
                            .then((res) => res.json())
                            .catch((error) => {
                                if (error) {
                                    logout();
                                }
                            })
                            .then((data) => {
                                if (data.code === 200) {
                                    localStorage.setItem('token', data.data.token);
                                    localStorage.setItem('id_negocio', data.data.negocio_id);
                                    localStorage.setItem('nombre_negocio', data.data.nombre);
                                    localStorage.setItem('pais', data.data.pais);

                                    localStorage.setItem('plan_comercial', data.data.plan_comercial);

                                    if (data.data.tipo_negocio === 5) {
                                        localStorage.setItem('tipo_negocio', 'food');
                                    } else {
                                        localStorage.setItem('tipo_negocio', 'market');
                                    }
                                    dispatch({
                                        type: LOGIN,
                                        payload: {
                                            isLoggedIn: true,
                                            user: {
                                                id: user.uid,
                                                email: user.email,
                                                name: user.displayName || 'Marcos Stevens'
                                                // id_negocio: data.data.id_negocio
                                            }
                                        }
                                    });
                                } else {
                                    alert('No tienes permisos para ingresar a esta plataforma');
                                    logout();
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    );

    const firebaseEmailPasswordSignIn = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

    const firebaseGoogleSignIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        return firebase.auth().signInWithPopup(provider);
    };

    const firebaseRegister = async (email, password) => firebase.auth().createUserWithEmailAndPassword(email, password);

    const resetPassword = async (email) => {
        await firebase.auth().sendPasswordResetEmail(email);
    };

    const updateProfile = () => {};
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseRegister,
                firebaseEmailPasswordSignIn,
                login: () => {},
                firebaseGoogleSignIn,
                logout,
                resetPassword,
                updateProfile
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

FirebaseProvider.propTypes = {
    children: PropTypes.node
};

export default FirebaseContext;
