// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
// initial state
const initialState = {
    error: null,
    openItem: ['dashboard'],
    activeGroup: null,
    drawerOpen: false,
    notifications: []
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        activeItem(state, action) {
            state.openItem = action.payload;
        },

        openDrawer(state, action) {
            state.drawerOpen = action.payload;
        },

        getNotificacionesSuccess(state, action) {
            state.notifications = action.payload;
        },

        activeGroup(state, action) {
            state.activeGroup = action.payload;
        },

        cleanGroup(state) {
            state.activeGroup = null;
        }
    }
});

export default menu.reducer;
export const { activeItem, openDrawer } = menu.actions;

// ==============================|| EXPORT REDUCER ||============================== //

export function getNotifications() {
    return async () => {
        try {
            const response = await axios.get('/getNotificaciones');
            dispatch(menu.actions.getNotificacionesSuccess(response.data.promociones));
        } catch (error) {
            // console.log(error);
        }
    };
}

export function setActiveGroup(id) {
    return async () => {
        try {
            dispatch(menu.actions.activeGroup(id));
        } catch (error) {
            dispatch(menu.actions.hasError(error));
        }
    };
}

export function cleanActiveGroup() {
    return async () => {
        try {
            dispatch(menu.actions.cleanGroup());
        } catch (error) {
            dispatch(menu.actions.hasError(error));
        }
    };
}
