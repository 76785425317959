// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconHome } from '@tabler/icons';

// constant
const icons = {
    IconHome
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: '',
    type: 'group',
    children: [
        {
            id: 'default',
            title: <FormattedMessage id="inicio" />,
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconHome,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
