import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isGlobalLoading: false, // Indica si hay una carga global en progreso
    globalError: null, // Almacena mensajes de error globales
    sharedData: {} // Objeto para almacenar datos compartidos en la aplicación
};

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setGlobalLoading(state, action) {
            state.isGlobalLoading = action.payload;
        },
        setGlobalError(state, action) {
            state.globalError = action.payload;
        },
        setSharedData(state, action) {
            state.sharedData = action.payload;
        }
    }
});

export const { setGlobalLoading, setGlobalError, setSharedData } = globalSlice.actions;
export default globalSlice.reducer;
