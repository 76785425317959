// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

// assets
import { IconBuildingStore } from '@tabler/icons';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = () => {
    const theme = useTheme();
    const { notifications } = useSelector((state) => state.menu);
    const { pedidos, cargando } = useSelector((state) => state.pedidos);

    const pedidosIngresados = pedidos.filter((pedido) => pedido.estado === 'INGRESADO');

    const [notificaciones, setNotificaciones] = useState([]);
    useEffect(() => {
        setNotificaciones(notifications);
    }, [notifications]);

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {notificaciones.map((item) => (
                <ListItemWrapper key={item.id}>
                    <Link to="/negocio/pedidos/administracion">
                        <ListItem alignItems="center">
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        color: theme.palette.success.dark,
                                        backgroundColor:
                                            theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
                                        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                                        borderColor: theme.palette.success.main
                                    }}
                                >
                                    <IconBuildingStore stroke={1.5} size="1.3rem" />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={<Typography variant="subtitle1">{item.mensaje}</Typography>} />
                            {/* <ListItemSecondaryAction>
                                <Grid container justifyContent="flex-end">
                                    <Grid item xs={12}>
                                        <Typography variant="caption" display="block" gutterBottom>
                                            2 min ago
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ListItemSecondaryAction> */}
                        </ListItem>
                    </Link>
                    <Grid container direction="column" className="list-container">
                        {/* <Grid item xs={12} sx={{ pb: 2 }}>
                                <Typography variant="subtitle2">We have successfully received your request.</Typography>
                            </Grid> */}
                        {/* <Grid item xs={12}>
                                <Grid container>
                                    <Grid item>
                                        <Chip label="Unread" sx={chipErrorSX} />
                                    </Grid>
                                </Grid>
                            </Grid> */}
                    </Grid>
                </ListItemWrapper>
            ))}
            {!notificaciones.length && !pedidosIngresados.length && !cargando ? <Grid item>No hay notificaciones nuevas</Grid> : <></>}
        </List>
    );
};

export default NotificationList;
