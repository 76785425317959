import pedidos from './pedidos';
import dashboard from './dashboard';
import products from './products';
import analiticas from './analiticas';
import descuentos from './descuentos';
import clientes from './clientes';
import customization from './customization';

// ==============================|| MENU ITEMS ||============================== //

const menuItemsAdmin = {
    items: [dashboard, pedidos, products, clientes, analiticas, descuentos, customization]
};

const menuItems = menuItemsAdmin;

export default menuItems;
