import React, { useState, useEffect } from 'react';
import { CircularProgress, IconButton, Switch, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateAceptaDelivery } from 'store/slices/sucursal';
import MopedOutlinedIcon from '@mui/icons-material/MopedOutlined';

const SwitchDelivery = () => {
    const dispatch = useDispatch();
    const [estado, setEstado] = useState(false);
    const { aceptaDelivery, isLoading } = useSelector((state) => state.sucursal);

    useEffect(() => {
        setEstado(aceptaDelivery);
    }, [aceptaDelivery]);

    const handleChange = (event) => {
        const newEstado = event.target.checked;
        setEstado(newEstado);
        dispatch(
            updateAceptaDelivery({
                estado: newEstado
            })
        );
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <Tooltip title={estado ? 'Acepta delivery' : 'No acepta delivery'} placement="top">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton>
                    <MopedOutlinedIcon />
                </IconButton>
                <Switch checked={estado} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
            </div>
        </Tooltip>
    );
};

export default SwitchDelivery;
