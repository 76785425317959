import { memo } from 'react';
import { Typography } from '@mui/material';
import NavGroup from './NavGroup';
import menuItemAdmin from 'menu-items/menuItemsAdmin';
import menuItemVendedor from 'menu-items/menuItemsVendedor';
import menuItemRudyAdmin from 'menu-items/menuItemsRudyAdmin';

const MenuList = ({ negocio }) => {
    const renderNavItems = (items) =>
        items.map((item) => {
            if (item.type === 'group') {
                return <NavGroup key={item.id} item={item} />;
            }
            return (
                <Typography key={item.id} variant="h6" color="error" align="center">
                    Menu Items Error
                </Typography>
            );
        });

    if (negocio.negocio_id === 445) {
        if (negocio.rol === 'ADMIN') {
            return <>{renderNavItems(menuItemRudyAdmin.items)}</>;
        }
        return <>{renderNavItems(menuItemVendedor.items)}</>;
    }

    const items = negocio.rol === 'ADMIN' ? menuItemAdmin.items : menuItemVendedor.items;

    return <>{renderNavItems(items)}</>;
};

export default memo(MenuList);
