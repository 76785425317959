import PropTypes from 'prop-types';
import logoRhino from 'assets/images/logoRhino.png';
import { ReactComponent as LogoHandy } from 'assets/images/logoHandy.svg';
import { TIENDA_HANDY } from 'config';

import './Logo.scss';

// ==============================|| LOGO SVG ||============================== //

const Logo = ({ color }) => {
    const svgStyle = {
        '--logo-color': color || '#fff'
    };

    return TIENDA_HANDY ? <LogoHandy style={svgStyle} /> : <img src={logoRhino} alt="Logo" width="150" />;
};

Logo.propTypes = {
    color: PropTypes.string.isRequired
};

export default Logo;
