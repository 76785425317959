import React, { useState, useEffect } from 'react';
import { CircularProgress, IconButton, Switch, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateAceptaTakeAway } from 'store/slices/sucursal';
import TakeoutDiningOutlinedIcon from '@mui/icons-material/TakeoutDiningOutlined';

const SwitchTakeAway = () => {
    const dispatch = useDispatch();
    const [estado, setEstado] = useState(false);
    const { aceptaTakeAway, isLoading } = useSelector((state) => state.sucursal);
    const sucursalActual = sessionStorage.getItem('id_sucursal');

    useEffect(() => {
        setEstado(aceptaTakeAway);
    }, [aceptaTakeAway]);

    const handleChange = (event) => {
        const newEstado = event.target.checked;
        setEstado(newEstado);
        dispatch(
            updateAceptaTakeAway({
                id: sucursalActual,
                estado: newEstado
            })
        );
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <Tooltip title={estado ? 'Acepta TakeAway' : 'No acepta TakeAway'} placement="top">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton>
                    <TakeoutDiningOutlinedIcon />
                </IconButton>
                <Switch checked={estado} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
            </div>
        </Tooltip>
    );
};

export default SwitchTakeAway;
