// third-party
// import { FormattedMessage } from 'react-intl';

// assets
import { IconReceipt } from '@tabler/icons';

// constant
const icons = {
    IconReceipt
};

// ==============================|| PRODUCTS MENU ITEMS ||============================== //

const pedidos = {
    id: 'Pedidos',
    title: '',
    type: 'group',
    children: [
        {
            id: 'Pedidos',
            title: 'Pedidos',
            type: 'collapse',
            url: '/negocio/pedidos/administracion',
            icon: icons.IconReceipt,
            breadcrumbs: false,
            children: [
                {
                    id: 'administacion',
                    title: 'Administración',
                    type: 'item',
                    url: '/negocio/pedidos/administracion',
                    breadcrumbs: false
                },
                {
                    id: 'historial',
                    title: 'Historial',
                    type: 'item',
                    url: '/negocio/pedidos/historial',
                    breadcrumbs: false,
                    hidden: false
                }
            ]
        }
    ]
};

export default pedidos;
