// assets
import { IconUser } from '@tabler/icons';

// constant
const icons = {
    IconUser
};

// ==============================|| PRODUCTS MENU ITEMS ||============================== //

const clientes = {
    id: 'Clientes',
    title: '',
    type: 'group',
    children: [
        {
            id: 'Clientes',
            title: 'Clientes',
            type: 'item',
            url: '/clientes/clientes-list',
            icon: icons.IconUser,
            breadcrumbs: false
        }
    ]
};

export default clientes;
